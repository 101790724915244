import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchList, getObj, addObj, putObj, delObj, roleMenuUpd, fetchRoleTree, fetchDeptTree } from '@api/admin/role';
import { fetchTree } from '@api/admin/menu';
export default {
  name: 'table_role',
  data: function data() {
    return {
      treeData: [],
      treeDeptData: [],
      checkedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      form: {
        roleName: undefined,
        roleCode: undefined,
        roleDesc: undefined,
        roleDeptName: undefined,
        roleDeptId: undefined
      },
      roleId: undefined,
      roleCode: undefined,
      rules: {
        roleName: [{
          required: true,
          message: '角色名称',
          trigger: 'blur'
        }, {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }],
        roleCode: [{
          required: true,
          message: '角色标识',
          trigger: 'blur'
        }, {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }],
        roleDesc: [{
          required: true,
          message: '角色标识',
          trigger: 'blur'
        }, {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }]
      },
      statusOptions: ['0', '1'],
      rolesOptions: undefined,
      dialogFormVisible: false,
      dialogDeptVisible: false,
      dialogPermissionVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建',
        roleMenu: '菜单授权'
      },
      tableKey: 0,
      roleManager_btn_add: false,
      roleManager_btn_edit: false,
      roleManager_btn_del: false,
      roleManager_btn_perm: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      fetchList(this.listQuery).then(function (response) {
        _this.list = response.data.records;
        _this.total = response.data.total;
        _this.listLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate: function handleCreate() {
      this.resetTemp();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      var _this2 = this;

      getObj(row.roleId).then(function (response) {
        _this2.form = response.data;
        _this2.form.roleDeptName = row.roleDeptName;
        _this2.form.roleDeptId = row.roleDeptId;
        _this2.dialogFormVisible = true;
        _this2.dialogStatus = 'update';
      });
    },
    handleMenu: function handleMenu(row) {
      var _this3 = this;

      fetchRoleTree(row.roleCode).then(function (response) {
        _this3.checkedKeys = response.data;
        return fetchTree();
      }).then(function (response) {
        _this3.treeData = response.data;
        _this3.dialogStatus = 'roleMenu';
        _this3.dialogPermissionVisible = true;
        _this3.roleId = row.roleId;
        _this3.roleCode = row.roleCode;
      });
    },
    handleDept: function handleDept() {
      var _this4 = this;

      fetchDeptTree().then(function (response) {
        _this4.treeDeptData = response.data;
        _this4.dialogDeptVisible = true;
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getNodeData: function getNodeData(data) {
      this.dialogDeptVisible = false;
      this.form.roleDeptId = data.id;
      this.form.roleDeptName = data.name;
    },
    handleDelete: function handleDelete(row) {
      var _this5 = this;

      this.$confirm('此操作将永久删除该角色(角色名:' + row.roleName + '), 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delObj(row.roleId).then(function (response) {
          _this5.dialogFormVisible = false;

          _this5.getList();

          _this5.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
        });
      });
    },
    create: function create(formName) {
      var _this6 = this;

      var set = this.$refs;
      set[formName].validate(function (valid) {
        if (valid) {
          addObj(_this6.form).then(function () {
            _this6.dialogFormVisible = false;

            _this6.getList();

            _this6.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success',
              duration: 2000
            });
          });
        } else {
          return false;
        }
      });
    },
    cancel: function cancel(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    },
    update: function update(formName) {
      var _this7 = this;

      var set = this.$refs;
      set[formName].validate(function (valid) {
        if (valid) {
          _this7.dialogFormVisible = false;
          putObj(_this7.form).then(function () {
            _this7.dialogFormVisible = false;

            _this7.getList();

            _this7.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            });
          });
        } else {
          return false;
        }
      });
    },
    updateRoleMenu: function updateRoleMenu(roleId, roleCode) {
      var _this8 = this;

      roleMenuUpd(roleId, this.$refs.menuTree.getCheckedKeys()).then(function () {
        _this8.dialogPermissionVisible = false;
        fetchTree().then(function (response) {
          _this8.treeData = response.data;
          return fetchRoleTree(roleCode);
        }).then(function (response) {
          _this8.checkedKeys = response.data;

          _this8.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            duration: 2000
          });
        });
      });
    },
    resetTemp: function resetTemp() {
      this.form = {
        id: undefined,
        roleName: undefined,
        roleCode: undefined,
        roleDesc: undefined
      };
    },
    handleUserSet: function handleUserSet(row) {
      this.$refs.roleMemberSet.init(row.roleId);
    },
    handlePermissionSet: function handlePermissionSet(row) {
      this.$refs.rolePermissionSet.init(row.roleId);
    }
  }
};