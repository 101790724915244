import _objectSpread from "/var/lib/jenkins/workspace/acquiring-front/hkt-portal-front/node_modules/@babel/runtime/helpers/builtin/es6/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread({}, mapActions('d2admin/account', ['logout']), {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        vm: this,
        confirm: true
      });
    }
  })
};