var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-submenu",
    { attrs: { index: _vm.menu.path || _vm.uniqueId } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.menu.icon
            ? _c("i", { class: "fa fa-" + _vm.menu.icon })
            : _vm._e(),
          (_vm.menu.icon === undefined) & !_vm.menu.iconSvg
            ? _c("i", { staticClass: "fa fa-folder-o" })
            : _vm._e(),
          _vm.menu.iconSvg
            ? _c("d2-icon-svg", { attrs: { name: _vm.menu.iconSvg } })
            : _vm._e(),
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.menu.title))
          ])
        ],
        1
      ),
      _vm._l(_vm.menu.children, function(child, childIndex) {
        return [
          child.children === undefined
            ? _c("d2-layout-header-aside-menu-item", {
                key: childIndex,
                attrs: { menu: child }
              })
            : _c("d2-layout-header-aside-menu-sub", {
                key: childIndex,
                attrs: { menu: child }
              })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }