var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "page",
      staticClass: "d2-theme-header-menu",
      class: { "is-scrollable": _vm.isScroll },
      attrs: { flex: "cross:center" }
    },
    [
      _c(
        "div",
        {
          ref: "content",
          staticClass: "d2-theme-header-menu__content",
          attrs: { "flex-box": "1", flex: "" }
        },
        [
          _c(
            "div",
            {
              ref: "scroll",
              staticClass: "d2-theme-header-menu__scroll",
              style: "transform: translateX(" + _vm.currentTranslateX + "px);",
              attrs: { "flex-box": "0" }
            },
            [
              _c(
                "el-menu",
                {
                  attrs: { mode: "horizontal", "default-active": _vm.active },
                  on: { select: _vm.handleMenuSelect }
                },
                [
                  _vm._l(_vm.header, function(menu, menuIndex) {
                    return [
                      menu.children === undefined
                        ? _c("d2-layout-header-aside-menu-item", {
                            key: menuIndex,
                            attrs: { menu: menu }
                          })
                        : _c("d2-layout-header-aside-menu-sub", {
                            key: menuIndex,
                            attrs: { menu: menu }
                          })
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _vm.isScroll
        ? _c(
            "div",
            {
              staticClass: "d2-theme-header-menu__prev",
              attrs: { "flex-box": "0", flex: "main:center cross:center" },
              on: {
                click: function($event) {
                  return _vm.scroll("left")
                }
              }
            },
            [_c("i", { staticClass: "el-icon-arrow-left" })]
          )
        : _vm._e(),
      _vm.isScroll
        ? _c(
            "div",
            {
              staticClass: "d2-theme-header-menu__next",
              attrs: { "flex-box": "0", flex: "cross:center" },
              on: {
                click: function($event) {
                  return _vm.scroll("right")
                }
              }
            },
            [_c("i", { staticClass: "el-icon-arrow-right" })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }