//
//
//
//
//
//
//
//
//
import { uniqueId } from 'lodash';
export default {
  name: 'd2-layout-header-aside-menu-item',
  props: {
    menu: {
      type: Object,
      required: false,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      uniqueId: uniqueId('d2-menu-empty-')
    };
  }
};