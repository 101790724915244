var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu-item",
    { attrs: { index: _vm.menu.path || _vm.uniqueId } },
    [
      _vm.menu.icon ? _c("i", { class: "fa fa-" + _vm.menu.icon }) : _vm._e(),
      (_vm.menu.icon === undefined) & !_vm.menu.iconSvg
        ? _c("i", { staticClass: "fa fa-file-o" })
        : _vm._e(),
      _vm.menu.iconSvg
        ? _c("d2-icon-svg", { attrs: { name: _vm.menu.iconSvg } })
        : _vm._e(),
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.menu.title || "未命名菜单"))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }