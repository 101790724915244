/* eslint-disable eol-last */

/* eslint-disable func-call-spacing */

/* eslint-disable indent */
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}, // iframe
{
  path: '/myiframe',
  redirect: '/myiframe',
  component: layoutHeaderAside,
  children: [{
    path: ':routerPath',
    name: 'iframe',
    meta: {
      auth: true,
      title: 'iframe'
    },
    component: function component() {
      return import('@/views/system/iframe');
    }
  }]
}, {
  path: '/admin',
  component: layoutHeaderAside,
  meta: {
    auth: true,
    title: '用户中心'
  },
  children: [{
    path: 'user',
    name: 'user',
    meta: {
      auth: true,
      title: '用户管理'
    },
    component: function component() {
      return import('@/views/portal/admin/user');
    }
  }, {
    path: 'menu',
    name: 'menu',
    meta: {
      auth: true,
      title: '菜单管理'
    },
    component: function component() {
      return import('@/views/portal/admin/menu');
    }
  }, {
    path: 'role',
    name: 'role',
    meta: {
      auth: true,
      title: '角色管理'
    },
    component: function component() {
      return import('@/views/portal/admin/role');
    }
  }, {
    path: 'dept',
    name: 'dept',
    meta: {
      auth: true,
      title: '部门管理'
    },
    component: function component() {
      return import('@/views/portal/admin/dept');
    }
  }]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default frameIn.concat(frameOut, errorPage);