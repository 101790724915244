














































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/portal/admin/role/index.vue"
}
