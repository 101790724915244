import request from '@/plugin/axios';
export function GetMenu() {
  return request({
    url: '/user/portal/hkt/menu/userMenu',
    method: 'get'
  });
}
export function fetchTree(query) {
  return request({
    url: '/user/menu/allTree',
    method: 'get',
    params: query
  });
}
export function addObj(obj) {
  return request({
    url: '/user/menu/',
    method: 'post',
    data: obj
  });
}
export function getObj(id) {
  return request({
    url: '/user/menu/' + id,
    method: 'get'
  });
}
export function delObj(id) {
  return request({
    url: '/user/menu/' + id,
    method: 'delete'
  });
}
export function putObj(obj) {
  return request({
    url: '/user/menu/',
    method: 'put',
    data: obj
  });
}