import request from '@/plugin/axios';
export function roleList() {
  return request({
    url: '/user/role/roleList',
    method: 'get'
  });
}
export function fetchList(query) {
  return request({
    url: '/user/role/rolePage',
    method: 'get',
    params: query
  });
}
export function deptRoleList(deptId) {
  return request({
    url: '/user/role/roleList/' + deptId,
    method: 'get'
  });
}
export function getObj(id) {
  return request({
    url: '/user/role/' + id,
    method: 'get'
  });
}
export function addObj(obj) {
  return request({
    url: '/user/role/',
    method: 'post',
    data: obj
  });
}
export function putObj(obj) {
  return request({
    url: '/user/role/',
    method: 'put',
    data: obj
  });
}
export function delObj(id) {
  return request({
    url: '/user/role/' + id,
    method: 'delete'
  });
}
export function roleMenuUpd(roleId, menuIds) {
  return request({
    url: '/user/role/roleMenuUpd',
    method: 'put',
    data: {
      roleId: roleId,
      menuIds: menuIds
    }
  });
}
export function fetchRoleTree(roleName) {
  return request({
    url: '/user/menu/roleTree/' + roleName,
    method: 'get'
  });
}
export function fetchDeptTree(query) {
  return request({
    url: '/user/dept/tree',
    method: 'get',
    params: query
  });
} // 成员设置

export function roleMembersUpd(roleId, userIds, operate) {
  return request({
    url: "/user/role/members/".concat(operate),
    method: 'put',
    data: {
      roleId: roleId,
      userIds: userIds
    }
  });
}
export function roleMembersAdded(roleId, params) {
  return request({
    url: "/user/role/members/added/".concat(roleId),
    method: 'get',
    params: params
  });
}
export function roleMembersNotin(roleId, params) {
  return request({
    url: "/user/role/members/notin/".concat(roleId),
    method: 'get',
    params: params
  });
} // 权限设置

export function rolePermissionsUpd(roleId, permissionIds, operate) {
  return request({
    url: "/user/role/permissions/".concat(operate),
    method: 'put',
    data: {
      roleId: roleId,
      permissionIds: permissionIds
    }
  });
}
export function rolePermissionsAdded(roleId, params) {
  return request({
    url: "/user/role/permissions/added/".concat(roleId),
    method: 'get',
    params: params
  });
}
export function rolePermissionsNotin(roleId, params) {
  return request({
    url: "/user/role/permissions/notin/".concat(roleId),
    method: 'get',
    params: params
  });
}