import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-integer";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchTree, getObj, addObj, delObj, putObj } from '@api/admin/menu';
export default {
  name: 'user-menu',
  data: function data() {
    var validateSort = function validateSort(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }

      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value < 1 || value > 999999) {
          callback(new Error('大小在 1 到 999999 之间'));
        } else {
          callback();
        }
      }
    };

    return {
      dialogFormVisible: false,
      treeData: [],
      form: {
        id: undefined,
        name: undefined,
        parentId: undefined,
        url: undefined,
        icon: undefined,
        sort: 999999,
        path: undefined
      },
      rules: {
        name: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          message: '长度在 2 到 50 个字符',
          trigger: 'blur'
        }],
        path: [{
          required: true,
          message: '请输入菜单路径',
          trigger: 'blur'
        }, {
          min: 2,
          max: 255,
          message: '长度在 2 到 255 个字符',
          trigger: 'blur'
        }],
        icon: [{
          required: true,
          message: '请选择菜单图标',
          trigger: 'change'
        }],
        sort: [{
          validator: validateSort,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  computed: {},
  methods: {
    getList: function getList() {
      var _this = this;

      fetchTree().then(function (response) {
        _this.treeData = response.data;
      });
    },
    // 新增菜单
    handleAdd: function handleAdd() {
      this.dialogFormVisible = true;
    },
    // 编辑菜单
    handleEdit: function handleEdit(id) {
      var _this2 = this;

      getObj(id).then(function (data) {
        _this2.form = data.data;
        _this2.dialogFormVisible = true;
      });
    },
    handleDelete: function handleDelete(id) {
      var _this3 = this;

      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delObj(id).then(function () {
          _this3.getList();

          _this3.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
        });
      });
    },
    submit: function submit() {
      var _this4 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (!_this4.form.parentId) {
            _this4.form.parentId = -1;
          }

          if (!_this4.form.id) {
            _this4.create();
          } else {
            _this4.update();
          }
        }
      });
    },
    update: function update() {
      var _this5 = this;

      putObj(this.form).then(function () {
        _this5.onCancel();

        _this5.getList();

        _this5.$notify({
          title: '成功',
          message: '更新成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    create: function create() {
      var _this6 = this;

      addObj(this.form).then(function () {
        _this6.onCancel();

        _this6.getList();

        _this6.$notify({
          title: '成功',
          message: '创建成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    onCancel: function onCancel() {
      this.dialogFormVisible = false;
      this.resetForm();
    },
    resetForm: function resetForm() {
      this.$refs.form.resetFields();
    }
  }
};