import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/web.dom.iterable";

/**
 * The file enables `@/store/crm.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */
var files = require.context('./modules', false, /\.js$/);

var modules = {};
files.keys().forEach(function (key) {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});
export default {
  namespaced: true,
  modules: modules
};