import request from '@/plugin/axios'; // 数据字典

export function getDictltems(query) {
  return request({
    url: "/user/dict/getDictItems/".concat(query),
    method: 'get'
  });
}
export function layout(token) {
  return request({
    url: "/auth/oauth/remove/token",
    method: 'get'
  });
}