import request from '@/plugin/axios';
/**
 * 登录
 * @param {用户名} username
 * @param {密码} password
 * @param {验证码} code
 * @param {验证码随机字符串} randomStr
 */

export var loginByUsername = function loginByUsername(username, password, code, randomStr) {
  var grantType = 'password_code'; // var scope = 'All'

  var accountType = 'HKT-PORTAL';
  return request({
    url: '/auth/oauth/token',
    headers: {
      'Authorization': 'Basic SEtULVBPUlRBTDoxMjM0NTY='
    },
    method: 'post',
    params: {
      username: username,
      password: password,
      'validCode': code,
      'grant_type': grantType,
      'account_type': accountType,
      'deviceId': randomStr
    }
  });
};
/**
 * 手机号登录
 *
 * @param {手机号} mobile
 * @param {验证码} code
 */

export var loginByMobile = function loginByMobile(mobile, code) {
  return request({
    url: '/auth/oauth/mobile',
    headers: {
      'Authorization': 'Basic NWQyMmViNmU4YjBjN2JhMDY2MDE0Mzk4OjEyMzQ1Ng=='
    },
    method: 'post',
    params: {
      mobile: mobile,
      code: code
    }
  });
};