








































export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/components/menu-sub/index.vue"
}
