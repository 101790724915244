import _objectSpread from "/var/lib/jenkins/workspace/acquiring-front/hkt-portal-front/node_modules/@babel/runtime/helpers/builtin/es6/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import d2MenuSide from './components/menu-side';
import d2MenuHeader from './components/menu-header';
import d2Tabs from './components/tabs';
import d2HeaderSearch from './components/header-search';
import d2HeaderTheme from './components/header-theme';
import d2HeaderUser from './components/header-user';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixinSearch from './mixins/search';
export default {
  name: 'd2-layout-header-aside',
  mixins: [mixinSearch],
  components: {
    d2MenuSide: d2MenuSide,
    d2MenuHeader: d2MenuHeader,
    d2Tabs: d2Tabs,
    d2HeaderSearch: d2HeaderSearch,
    d2HeaderTheme: d2HeaderTheme,
    d2HeaderUser: d2HeaderUser
  },
  data: function data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '65px'
    };
  },
  computed: _objectSpread({}, mapState('d2admin', {
    keepAlive: function keepAlive(state) {
      return state.page.keepAlive;
    },
    grayActive: function grayActive(state) {
      return state.gray.active;
    },
    transitionActive: function transitionActive(state) {
      return state.transition.active;
    },
    asideCollapse: function asideCollapse(state) {
      return state.menu.asideCollapse;
    }
  }), mapGetters('d2admin', {
    themeActiveSetting: 'theme/activeSetting'
  }), {
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup: function styleLayoutMainGroup() {
      return _objectSpread({}, this.themeActiveSetting.backgroundImage ? {
        backgroundImage: "url('".concat(this.$baseUrl).concat(this.themeActiveSetting.backgroundImage, "')")
      } : {});
    }
  }),
  methods: _objectSpread({}, mapActions('d2admin/menu', ['asideCollapseToggle']), {
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside: function handleToggleAside() {
      this.asideCollapseToggle();
    }
  })
};