var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "default", icon: "el-icon-refresh" },
              on: { click: _vm.getList }
            },
            [_vm._v("刷 新")]
          ),
          _c(
            "div",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-plus"
                  },
                  on: { click: _vm.handlerAdd }
                },
                [_vm._v("新 增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "tree-table",
        { staticStyle: { width: "98%" }, attrs: { data: _vm.treeData } },
        [
          _c("el-table-column", {
            attrs: { label: "名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "排序" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderNum))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-edit",
                        size: "mini"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handlerEdit(scope.row.id)
                        }
                      }
                    }),
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        type: "danger",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handlerDelete(scope.row.id)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增/编辑部门",
            visible: _vm.dialogFormVisible,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级部门", prop: "parentId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "不选默认顶级部门",
                        disabled: _vm.isEdit
                      },
                      model: {
                        value: _vm.form.parentId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "parentId", $$v)
                        },
                        expression: "form.parentId"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "顶级部门", value: 0 }
                      }),
                      _vm._l(_vm.listAll, function(item) {
                        return _c("el-option", {
                          key: item.deptId,
                          attrs: { label: item.name, value: item.deptId }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "部门名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "最多输入50个字符" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "orderNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "只能输入正整数, 最大999" },
                    model: {
                      value: _vm.form.orderNum,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "orderNum", _vm._n($$v))
                      },
                      expression: "form.orderNum"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: { click: _vm.onCancel }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-check" },
                  on: { click: _vm.submit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }