var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d2-badge" }, [
      _c("p", { attrs: { align: "center" } }, [
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/release/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/forks/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/stars/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/issues/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/issues-closed/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/issues-pr/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/issues-pr-closed/d2-projects/d2-admin.svg"
            }
          })
        ])
      ]),
      _c("p", { attrs: { align: "center" } }, [
        _c("a", [
          _c("img", {
            attrs: {
              src: "https://img.shields.io/npm/v/@d2-admin/ice-scaffold.svg"
            }
          })
        ]),
        _c(
          "a",
          { attrs: { href: "https://www.travis-ci.org/d2-projects/d2-admin" } },
          [
            _c("img", {
              attrs: {
                src:
                  "https://www.travis-ci.org/d2-projects/d2-admin.svg?branch=master"
              }
            })
          ]
        ),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/github/last-commit/d2-projects/d2-admin.svg"
            }
          })
        ]),
        _c("a", [
          _c("img", {
            attrs: {
              src:
                "https://img.shields.io/badge/code_style-standard-brightgreen.svg"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }