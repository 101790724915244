import _objectSpread from "/var/lib/jenkins/workspace/acquiring-front/hkt-portal-front/node_modules/@babel/runtime/helpers/builtin/es6/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { throttle } from 'lodash';
import { mapState } from 'vuex';
import menuMixin from '../mixin/menu';
import d2LayoutMainMenuItem from '../components/menu-item/index.vue';
import d2LayoutMainMenuSub from '../components/menu-sub/index.vue';
export default {
  name: 'd2-layout-header-aside-menu-header',
  mixins: [menuMixin],
  components: {
    'd2-layout-header-aside-menu-item': d2LayoutMainMenuItem,
    'd2-layout-header-aside-menu-sub': d2LayoutMainMenuSub
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['header'])),
  data: function data() {
    return {
      active: '',
      isScroll: false,
      scrollWidth: 0,
      contentWidth: 0,
      currentTranslateX: 0,
      throttledCheckScroll: null
    };
  },
  watch: {
    '$route.matched': {
      handler: function handler(val) {
        this.active = val[val.length - 1].path;
      },
      immediate: true
    }
  },
  methods: {
    scroll: function scroll(direction) {
      if (direction === 'left') {
        // 向右滚动
        this.currentTranslateX = 0;
      } else {
        // 向左滚动
        if (this.contentWidth * 2 - this.currentTranslateX <= this.scrollWidth) {
          this.currentTranslateX -= this.contentWidth;
        } else {
          this.currentTranslateX = this.contentWidth - this.scrollWidth;
        }
      }
    },
    checkScroll: function checkScroll() {
      var _this = this;

      var contentWidth = this.$refs.content.clientWidth;
      var scrollWidth = this.$refs.scroll.clientWidth;

      if (this.isScroll) {
        // 页面依旧允许滚动的情况，需要更新width
        if (this.contentWidth - this.scrollWidth === this.currentTranslateX) {
          // currentTranslateX 也需要相应变化【在右端到头的情况时】
          this.currentTranslateX = contentWidth - scrollWidth; // 快速的滑动依旧存在判断和计算时对应的contentWidth变成正数，所以需要限制一下

          if (this.currentTranslateX > 0) {
            this.currentTranslateX = 0;
          }
        } // 更新元素数据


        this.contentWidth = contentWidth;
        this.scrollWidth = scrollWidth; // 判断何时滚动消失: 当scroll > content

        if (contentWidth > scrollWidth) {
          this.isScroll = false;
        }
      } // 判断何时滚动出现: 当scroll < content


      if (!this.isScroll && contentWidth < scrollWidth) {
        this.isScroll = true; // 注意，当isScroll变为true，对应的元素盒子大小会发生变化

        this.$nextTick(function () {
          contentWidth = _this.$refs.content.clientWidth;
          scrollWidth = _this.$refs.scroll.clientWidth;
          _this.contentWidth = contentWidth;
          _this.scrollWidth = scrollWidth;
          _this.currentTranslateX = 0;
        });
      }
    }
  },
  mounted: function mounted() {
    // 初始化判断
    // 默认判断父元素和子元素的大小，以确定初始情况是否显示滚动
    window.addEventListener('load', this.checkScroll);
    this.checkScroll(); // 全局窗口变化监听，判断父元素和子元素的大小，从而控制isScroll的开关

    this.throttledCheckScroll = throttle(this.checkScroll, 300);
    window.addEventListener('resize', this.throttledCheckScroll);
  },
  beforeDestroy: function beforeDestroy() {
    // 取消监听
    window.removeEventListener('resize', this.throttledCheckScroll);
    window.removeEventListener('load', this.checkScroll);
  }
};