























































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/portal/admin/user/index.vue"
}
