import _objectSpread from "/var/lib/jenkins/workspace/acquiring-front/hkt-portal-front/node_modules/@babel/runtime/helpers/builtin/es6/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  name: 'd2-theme-list',
  data: function data() {
    return {
      table: {
        showHeader: false,
        border: true
      }
    };
  },
  computed: _objectSpread({}, mapState('d2admin/theme', ['list', 'activeName'])),
  methods: _objectSpread({}, mapActions('d2admin/theme', ['set']), {
    handleSelectTheme: function handleSelectTheme(name) {
      this.set(name);
    }
  })
};