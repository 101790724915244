var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d2-page-cover" },
    [_vm._t("footer"), _c("a", { attrs: { target: "blank", href: "#" } })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }