var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-form",
            {
              staticStyle: { "margin-bottom": "-18px" },
              attrs: { inline: true, model: _vm.listQuery, size: "mini" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "username" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "用户名", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilter($event)
                      }
                    },
                    model: {
                      value: _vm.listQuery.username,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "username", $$v)
                      },
                      expression: "listQuery.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", icon: "el-icon-search" },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v("搜 索")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.handleCreate }
                    },
                    [_vm._v("新 增")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "98%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "拼命加载中...",
            "highlight-current-row": "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.userId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      scope.row.avatar
                        ? _c("img", {
                            staticClass: "user-avatar",
                            staticStyle: {
                              width: "20px",
                              height: "20px",
                              "border-radius": "50%"
                            },
                            attrs: {
                              src: scope.row.avatar + "?imageView2/1/w/20/h/20"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(scope.row.username) + "\n        ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "手机号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所属部门",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.deptName) + " ")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "角色" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.roleList, function(role) {
                    return _c("span", { key: role.id }, [
                      _vm._v(_vm._s(role.roleDesc) + " ")
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.createTime,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "标签" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.label
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s(scope.row.label))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(_vm._s(_vm._f("statusFilter")(scope.row.delFlag)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-edit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleUpdate(scope.row)
                        }
                      }
                    }),
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        type: "danger",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deletes(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "-10px" },
            attrs: {
              background: "",
              "current-page": _vm.listQuery.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.listQuery.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
              "update:currentPage": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogDeptVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDeptVisible = $event
            }
          }
        },
        [
          _c("el-tree", {
            ref: "deptTree",
            staticClass: "filter-tree",
            attrs: {
              data: _vm.treeDeptData,
              "default-checked-keys": _vm.checkedKeys,
              "check-strictly": "",
              "node-key": "id",
              "highlight-current": "",
              props: _vm.defaultProps,
              "default-expand-all": ""
            },
            on: { "node-click": _vm.getNodeData }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输用户名" },
                    model: {
                      value: _vm.form.username,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username"
                    }
                  })
                ],
                1
              ),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "密码",
                        placeholder: "请输入密码",
                        prop: "newpassword1"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.form.newpassword1,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "newpassword1", $$v)
                          },
                          expression: "form.newpassword1"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "所属部门", prop: "deptId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "选择部门", readonly: "" },
                    on: {
                      focus: function($event) {
                        return _vm.handleDept()
                      }
                    },
                    model: {
                      value: _vm.form.deptName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "deptName", $$v)
                      },
                      expression: "form.deptName"
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.deptId,
                        expression: "form.deptId"
                      }
                    ],
                    attrs: { type: "hidden" },
                    domProps: { value: _vm.form.deptId },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "deptId", $event.target.value)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "验证码登录使用" },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签", prop: "label" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "多个标签 ',' 隔开" },
                    model: {
                      value: _vm.form.label,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "label", $$v)
                      },
                      expression: "form.label"
                    }
                  })
                ],
                1
              ),
              _vm.dialogStatus == "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "delFlag" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.delFlag,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "delFlag", $$v)
                            },
                            expression: "form.delFlag"
                          }
                        },
                        _vm._l(_vm.statusOptions, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: {
                              label: _vm._f("statusFilter")(item),
                              value: item
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close" },
                  on: {
                    click: function($event) {
                      return _vm.cancel("form")
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-check" },
                      on: {
                        click: function($event) {
                          return _vm.create("form")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-check" },
                      on: {
                        click: function($event) {
                          return _vm.update("form")
                        }
                      }
                    },
                    [_vm._v("修 改")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }