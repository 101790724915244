import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchList, getObj, addObj, putObj, delObj } from '@api/admin/user';
import { fetchDeptTree } from '@api/admin/role';
import ElOption from 'element-ui/packages/select/src/option';
export default {
  components: {
    ElOption: ElOption
  },
  name: 'table_user',
  data: function data() {
    return {
      treeDeptData: [],
      checkedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      form: {
        username: undefined,
        newpassword1: undefined,
        delFlag: undefined,
        deptId: undefined,
        phone: undefined,
        label: undefined
      },
      rules: {
        username: [{
          required: true,
          message: '请输入账户',
          trigger: 'blur'
        }, {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }, {
          trigger: 'blur',
          validator: function validator(rule, value, callback) {
            var reg = /^[a-zA-Z0-9]+$/;

            if (!reg.test(value)) {
              callback(new Error('只能包含英文和数字'));
            } else {
              callback();
            }
          }
        }],
        newpassword1: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 20,
          message: '长度在 6 到 20 个字符',
          trigger: 'blur'
        }],
        deptId: [{
          required: true,
          message: '请选择部门',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '手机号',
          trigger: 'blur'
        }, {
          min: 11,
          max: 11,
          message: '长度在11 个字符',
          trigger: 'blur'
        }]
      },
      statusOptions: ['0', '1'],
      dialogFormVisible: false,
      dialogDeptVisible: false,
      userAdd: false,
      userUpd: false,
      userDel: false,
      dialogStatus: '',
      textMap: {
        update: '编辑用户',
        create: '新增用户'
      },
      isDisabled: {
        0: false,
        1: true
      },
      tableKey: 0
    };
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: '有效',
        1: '无效',
        9: '锁定'
      };
      return statusMap[status];
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      fetchList(this.listQuery).then(function (response) {
        _this.list = response.data.records;
        _this.total = response.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    getNodeData: function getNodeData(data) {
      this.dialogDeptVisible = false;
      this.form.deptId = data.id;
      this.form.deptName = data.name;
    },
    handleDept: function handleDept() {
      var _this2 = this;

      fetchDeptTree().then(function (response) {
        _this2.treeDeptData = response.data;
        _this2.dialogDeptVisible = true;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate: function handleCreate() {
      this.resetTemp();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      getObj(row.userId).then(function (response) {
        _this3.form = response.data;
        _this3.dialogFormVisible = true;
        _this3.dialogStatus = 'update';
      });
    },
    create: function create(formName) {
      var _this4 = this;

      var set = this.$refs;
      set[formName].validate(function (valid) {
        if (valid) {
          addObj(_this4.form).then(function (_ref) {
            var data = _ref.data;

            if (data.result) {
              _this4.getList();

              _this4.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success',
                duration: 2000
              });
            }
          }).finally(function () {
            _this4.dialogFormVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    cancel: function cancel(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    },
    update: function update(formName) {
      var _this5 = this;

      var set = this.$refs;
      set[formName].validate(function (valid) {
        if (valid) {
          _this5.dialogFormVisible = false;
          _this5.form.password = undefined;
          putObj(_this5.form).then(function (_ref2) {
            var data = _ref2.data;

            if (data.result) {
              _this5.getList();

              _this5.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success',
                duration: 2000
              });
            }
          }).finally(function () {
            _this5.dialogFormVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    deletes: function deletes(row) {
      var _this6 = this;

      this.$confirm('此操作将永久删除该用户(用户名:' + row.username + '), 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delObj(row.userId).then(function (_ref3) {
          var data = _ref3.data;

          if (data.result) {
            _this6.getList();

            _this6.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
          }
        }).finally(function () {
          _this6.dialogFormVisible = false;
        });
      });
    },
    resetTemp: function resetTemp() {
      this.form = {
        id: undefined,
        username: '',
        password: '',
        delFlag: '',
        deptId: '',
        phone: ''
      };
    }
  }
};