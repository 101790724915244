import request from '@/plugin/axios';
export function fetchList(query) {
  return request({
    url: '/user/user/userPage',
    method: 'get',
    params: query
  });
}
export function addObj(obj) {
  return request({
    url: '/user/user/add',
    method: 'post',
    data: obj
  });
}
export function getObj(id) {
  return request({
    url: '/user/user/' + id,
    method: 'get'
  });
}
export function delObj(id) {
  return request({
    url: '/user/user/' + id,
    method: 'delete'
  });
}
export function putObj(obj) {
  return request({
    url: '/user/user/update',
    method: 'post',
    data: obj
  });
}
export function sendSmsCode(mobile) {
  return request({
    url: "/user/user/smsCode/".concat(mobile),
    method: 'get'
  });
}