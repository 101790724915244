var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [_c("d2-page-cover", [_c("template", { slot: "footer" })], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }