import _objectSpread from "/var/lib/jenkins/workspace/acquiring-front/hkt-portal-front/node_modules/@babel/runtime/helpers/builtin/es6/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import menuMixin from '../mixin/menu';
import d2LayoutMainMenuItem from '../components/menu-item/index.vue';
import d2LayoutMainMenuSub from '../components/menu-sub/index.vue';
import BScroll from 'better-scroll';
export default {
  name: 'd2-layout-header-aside-menu-side',
  mixins: [menuMixin],
  components: {
    'd2-layout-header-aside-menu-item': d2LayoutMainMenuItem,
    'd2-layout-header-aside-menu-sub': d2LayoutMainMenuSub
  },
  data: function data() {
    return {
      active: '',
      asideHeight: 300,
      BS: null
    };
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['aside', 'asideCollapse'])),
  watch: {
    // 折叠和展开菜单的时候销毁 better scroll
    asideCollapse: function asideCollapse(val) {
      var _this = this;

      this.scrollDestroy();
      setTimeout(function () {
        _this.scrollInit();
      }, 500);
    },
    // 监听路由 控制侧边栏激活状态
    '$route': {
      handler: function handler(_ref) {
        var _this2 = this;

        var fullPath = _ref.fullPath;
        this.active = fullPath;
        this.$nextTick(function () {
          if (_this2.aside.length > 0 && _this2.$refs.menu) {
            _this2.$refs.menu.activeIndex = fullPath;
          }
        });
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.scrollInit();
  },
  beforeDestroy: function beforeDestroy() {
    this.scrollDestroy();
  },
  methods: {
    scrollInit: function scrollInit() {
      this.BS = new BScroll(this.$el, {
        mouseWheel: true,
        click: true // 如果你愿意可以打开显示滚动条
        // scrollbar: {
        //   fade: true,
        //   interactive: false
        // }

      });
    },
    scrollDestroy: function scrollDestroy() {
      // https://github.com/d2-projects/d2-admin/issues/75
      try {
        this.BS.destroy();
      } catch (e) {
        delete this.BS;
        this.BS = null;
      }
    }
  }
};