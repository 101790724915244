//
//
//
//
//
//
//
//
//
import d2PageCover from './components/d2-page-cover';
export default {
  components: {
    d2PageCover: d2PageCover
  }
};