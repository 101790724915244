import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-integer";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listAll, fetchTree, getObj, addObj, delObj, putObj } from '@api/admin/dept';
export default {
  name: 'department',
  data: function data() {
    var validateSort = function validateSort(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }

      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value < 1 || value > 999) {
          callback(new Error('大小在 1 到 999 之间'));
        } else {
          callback();
        }
      }
    };

    return {
      dialogFormVisible: false,
      isEdit: false,
      listAll: [],
      treeData: [],
      form: {
        name: undefined,
        orderNum: 99,
        parentId: undefined,
        deptId: undefined
      },
      rules: {
        name: [{
          required: true,
          message: '请输入部门名称',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          message: '长度在 2 到 50 个字符',
          trigger: 'blur'
        }],
        orderNum: [{
          validator: validateSort,
          trigger: 'blur'
        }]
      }
    };
  },
  filters: {
    typeFilter: function typeFilter(type) {
      var typeMap = {
        0: '菜单',
        1: '按钮'
      };
      return typeMap[type];
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getAllList: function getAllList() {
      var _this = this;

      listAll().then(function (response) {
        _this.listAll = response.data;
      });
    },
    getList: function getList() {
      var _this2 = this;

      fetchTree().then(function (response) {
        _this2.treeData = response.data;
      });
    },
    submit: function submit() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (!_this3.form.parentId) {
            _this3.form.parentId = 0;
          }

          if (!_this3.form.deptId) {
            _this3.create();
          } else {
            _this3.update();
          }
        }
      });
    },
    handlerEdit: function handlerEdit(id) {
      var _this4 = this;

      getObj(id).then(function (data) {
        _this4.form = data.data;

        _this4.getAllList();

        _this4.isEdit = true;
        _this4.dialogFormVisible = true;
      });
    },
    handlerAdd: function handlerAdd() {
      this.getAllList();
      this.resetForm();
      this.isEdit = false;
      this.dialogFormVisible = true;
    },
    handlerDelete: function handlerDelete(id) {
      var _this5 = this;

      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delObj(id).then(function (_ref) {
          var data = _ref.data;

          if (data.status && data.status === 'FAILED') {
            return;
          }

          _this5.getList();

          _this5.resetForm();

          _this5.onCancel();

          _this5.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
        });
      });
    },
    update: function update() {
      var _this6 = this;

      putObj(this.form).then(function () {
        _this6.onCancel();

        _this6.getList();

        _this6.$notify({
          title: '成功',
          message: '更新成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    create: function create() {
      var _this7 = this;

      addObj(this.form).then(function () {
        _this7.onCancel();

        _this7.getList();

        _this7.$notify({
          title: '成功',
          message: '创建成功',
          type: 'success',
          duration: 2000
        });
      });
    },
    onCancel: function onCancel() {
      this.dialogFormVisible = false;
      this.resetForm();
    },
    resetForm: function resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    }
  }
};