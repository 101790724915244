























































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/system/login/page.vue"
}
