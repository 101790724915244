//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { uniqueId } from 'lodash'; // 组件

import d2LayoutMainMenuItem from '../menu-item';
export default {
  name: 'd2-layout-header-aside-menu-sub',
  components: {
    'd2-layout-header-aside-menu-item': d2LayoutMainMenuItem
  },
  props: {
    menu: {
      type: Object,
      required: false,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      uniqueId: uniqueId('d2-menu-empty-')
    };
  }
};