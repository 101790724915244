var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d2-layout-header-aside-menu-side" },
    [
      _c(
        "el-menu",
        {
          ref: "menu",
          attrs: {
            collapse: _vm.asideCollapse,
            "unique-opened": true,
            "default-active": _vm.active
          },
          on: { select: _vm.handleMenuSelect }
        },
        [
          _vm._l(_vm.aside, function(menu, index) {
            return [
              menu.children === undefined
                ? _c("d2-layout-header-aside-menu-item", {
                    key: index,
                    attrs: { menu: menu }
                  })
                : _c("d2-layout-header-aside-menu-sub", {
                    key: menu.path,
                    attrs: { menu: menu }
                  })
            ]
          })
        ],
        2
      ),
      _vm.aside.length === 0 && !_vm.asideCollapse
        ? _c(
            "div",
            {
              staticClass: "d2-layout-header-aside-menu-empty",
              attrs: { flex: "dir:top main:center cross:center" }
            },
            [
              _c("d2-icon", { attrs: { name: "inbox" } }),
              _c("span", [_vm._v("没有侧栏菜单")])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }