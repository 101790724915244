import "core-js/modules/es6.object.assign";
import _objectSpread from "/var/lib/jenkins/workspace/acquiring-front/hkt-portal-front/node_modules/@babel/runtime/helpers/builtin/es6/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import util from '@/libs/util';
import { sendSmsCode } from '@api/admin/user';
import './style.scss';
export default {
  data: function data() {
    return {
      timer: null,
      // 定时器名称
      timeInterval: null,
      time: dayjs().format('HH:mm:ss'),
      activeName: 'first',
      // 表单
      formLogin: {
        username: '',
        password: '',
        randomStr: '',
        code: ''
      },
      // 校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      mobileLogin: {
        mobile: '17034642111',
        code: ''
      },
      mobileRules: {
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
          message: '手机号不合法',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      code: {
        len: 4,
        src: ''
      },
      sms: {
        btnName: '发送短信',
        isDisabled: false,
        time: 60
      }
    };
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['status'])),
  // watch: {
  //   status (newdata) {
  //     console.log(newdata)
  //   }
  // },
  mounted: function mounted() {
    var _this = this;

    this.timeInterval = setInterval(function () {
      _this.refreshTime();
    }, 1000);
    this.refreshCode();
    var timer = setInterval(function () {
      setTimeout(_this.refreshCode, 0);
    }, 1000 * 60);
    this.$once('hook:beforeDestroy', function () {
      clearInterval(timer);
    });
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  methods: _objectSpread({}, mapActions('d2admin/account', ['login', 'loginByPhone']), {
    refreshTime: function refreshTime() {
      this.time = dayjs().format('HH:mm:ss');
    },

    /**
     * 刷新验证码
     */
    refreshCode: function refreshCode() {
      this.formLogin.code = '';
      this.formLogin.randomStr = util.randomLenNum(this.code.len, true);
      this.code.src = "".concat(process.env.VUE_APP_BASE_URL, "/auth/validata/code/") + this.formLogin.randomStr;
    },

    /**
     * 用户密码登录
     */
    submit: function submit() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.login(Object.assign({
            vm: _this2
          }, _this2.formLogin)); // console.log(error)


          if (!_this2.status) {
            _this2.refreshCode();
          }
        }
      });
    },

    /**
     *  发送短信
     */
    sendMsg: function sendMsg() {
      var _this3 = this;

      var me = this;
      me.$refs.mobileForm.validateField('mobile', function (error) {
        if (!error) {
          sendSmsCode(me.mobileLogin.mobile).then(function (_ref) {
            var data = _ref.data;

            if (data.status === 'SUCCEED') {
              _this3.$notify({
                title: '验证码发送成功',
                message: '验证码为:' + data.result,
                type: 'success',
                duration: 6000
              });

              doInterval();
            }
          });
        }
      });

      function doInterval() {
        me.sms.isDisabled = true;
        var interval = window.setInterval(function () {
          me.sms.btnName = '（' + me.sms.time + '秒）后重新发送';
          --me.sms.time;

          if (me.sms.time < 0) {
            me.sms.btnName = '重新发送';
            me.sms.time = 10;
            me.sms.isDisabled = false;
            window.clearInterval(interval);
          }
        }, 1000);
      }
    },

    /**
     * 手机号登录
     */
    mobileSubmit: function mobileSubmit() {
      var _this4 = this;

      this.$refs.mobileForm.validate(function (valid) {
        if (valid) {
          _this4.loginByPhone({
            vm: _this4,
            mobile: _this4.mobileLogin.mobile,
            code: _this4.mobileLogin.code
          });
        }
      });
    }
  })
};